<template>
    <div class="business-line-data-table">
        <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            tooltip-effect="light"
        >
            <el-table-column
                prop="time"
                align="left"
                width="160"
                :show-overflow-tooltip="true"
                label="时间"
            >
            </el-table-column>
            <el-table-column
                prop="customerName"
                align="left"
                width="120"
                :show-overflow-tooltip="true"
                label="客户"
            >
                <template slot="header" slot-scope="{ column }">
                    <select-popover
                        :maxSelect="5"
                        :enableCount="true"
                        :title="column.label"
                        :enableSearch="true"
                        :selectItem="customerArray"
                        @selectChange="selectCustomerChange"
                    ></select-popover>
                </template>
            </el-table-column>
            <el-table-column
                prop="customerDomain"
                align="left"
                width="120"
                :show-overflow-tooltip="true"
                label="所属领域"
            >
                <template slot-scope="{ row }">{{
                    row.customerDomain || "-"
                }}</template>
            </el-table-column>
            <el-table-column
                prop="businessGroupName"
                align="left"
                width="120"
                :show-overflow-tooltip="true"
                label="业务分组"
            >
                <template slot="header" slot-scope="{ column }">
                    <select-popover
                        :maxSelect="5"
                        :enableCount="false"
                        :title="column.label"
                        :enableSearch="true"
                        :selectItem="businessGroupArray"
                        @selectChange="businessGroupChange"
                    ></select-popover>
                </template>
            </el-table-column>
            <el-table-column
                prop="locations"
                align="left"
                width="100"
                :show-overflow-tooltip="true"
                label="地点"
            >
            </el-table-column>
            <el-table-column
                prop="customerDepartmentName"
                align="left"
                width="150"
                :show-overflow-tooltip="true"
                label="事业群/事业部"
            >
                <template slot="header" slot-scope="{ column }">
                    <select-popover
                        :maxSelect="5"
                        :enableCount="false"
                        :title="column.label"
                        :enableSearch="true"
                        :selectItem="departmentArray"
                        @selectChange="selectDepartmentChange"
                    ></select-popover>
                </template>
                <template slot-scope="{ row }">{{row.customerDepartmentName || '-'}}</template>
            </el-table-column>
            <el-table-column
                prop="jobName"
                align="left"
                width="100"
                :show-overflow-tooltip="true"
                label="职位名称"
            >
            </el-table-column>
            <el-table-column
                prop="jobCategorieName"
                align="left"
                width="120"
                :show-overflow-tooltip="true"
                label="职位类别"
            >
                <template slot="header" slot-scope="{ column }">
                    <select-popover
                        :maxSelect="5"
                        :enableCount="false"
                        :title="column.label"
                        :enableSearch="true"
                        :selectItem="jobArray"
                        @selectChange="selectJobChange"
                    ></select-popover>
                </template>
                <template slot-scope="{ row }">{{row.jobCategorieName || '-'}}</template>
            </el-table-column>
            <el-table-column
                prop="pm"
                align="left"
                width="100"
                :show-overflow-tooltip="true"
                label="PM"
            >
            </el-table-column>
            <el-table-column
                prop="firmTeamNames"
                align="left"
                width="120"
                :show-overflow-tooltip="true"
                label="所属团队"
            >
            </el-table-column>
            <el-table-column
                prop="recommendationCount"
                align="left"
                width="100"
                :show-overflow-tooltip="true"
                label="推荐"
            >
                <template slot-scope="{ row }">{{row.recommendationCount || '-'}}</template>
            </el-table-column>
            <el-table-column
                prop="arrangeInterviewCount"
                align="left"
                width="100"
                :show-overflow-tooltip="true"
                label="面试"
            >
                <template slot-scope="{ row }">{{row.arrangeInterviewCount || '-'}}</template>
            </el-table-column>
            <el-table-column
                prop="offerCount"
                align="left"
                width="100"
                :show-overflow-tooltip="true"
                label="offer"
            >
                <template slot-scope="{ row }">{{row.offerCount || '-'}}</template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import SelectPopover from '#/component/common/select-popover.vue';

export default {
    name: "business-line-data-table",
    props: {
        tableData: Array,
        loading: Boolean,
        extraData: {
            type: Array,
            default: function() {
                return []
            }
        },
        cacheFilterParams: Object || null,
    },
    components: {
        SelectPopover
    },
    data() {
        return {};
    },
    filters: {
        dataFilter(val) {
            return moment(val).format("YYYY-MM-DD");
        },
    },
    computed: {
        // 客户
        customerArray() {
            let _customerIds = [];
            let _customer = [];
            this.extraData.forEach((item) => {
                // _customer.push({
                //     ...item,
                //     label: item.customerName,
                //     count: item.total,
                // });

                // 去除数组对象里面的重复对象
                if(item.customerId && _customerIds.indexOf(item.customerId) == -1) {
                    _customerIds.push(item.customerId)
                    _customer.push({
                        ...item,
                        label: item.customerName,
                        count: item.total,
                    });
                }
            });
            return _customer;
        },
        // 事业群/事业部
        departmentArray() {
            let _customerDeparments = [];
            this.extraData.forEach((item) => {
                if (this.isLegalCustomer(item.customerId)) {
                    item.customerDeparments &&
                        item.customerDeparments.forEach((subItem) => {
                            const _index = _customerDeparments.findIndex(
                                (_item) => _item.id == subItem.id
                            );
                            if (_index < 0) {
                                _customerDeparments.push({
                                    ...subItem,
                                    label: subItem.chain,
                                });
                            }
                        });
                }
            });
            return _customerDeparments;
        },
        // 职位
        jobArray() {
            let _jobCategorie = [];
            this.extraData.forEach((item) => {
                if (this.isLegalCustomer(item.customerId)) {
                    item.jobCategorie &&
                        item.jobCategorie.forEach((subItem) => {
                            const _index = _jobCategorie.findIndex(
                                (_item) => _item.id == subItem.id
                            );
                            if (_index < 0) {
                                _jobCategorie.push({
                                    ...subItem,
                                    label: subItem.name,
                                });
                            }
                        });
                }
            });
            // 去除id为null的
            _jobCategorie = _jobCategorie.filter(el => {
                return el.id;
            })
            return _jobCategorie;
        },
        businessGroupArray() {
            let aBusinessGroup = [];
            this.extraData.forEach((item) => {
                if (this.isLegalCustomer(item.customerId)) {
                    item.businessGroups &&
                        item.businessGroups.forEach((subItem) => {
                            const _index = aBusinessGroup.findIndex(
                                (_item) => _item.id == subItem.id
                            );
                            if (_index < 0) {
                                aBusinessGroup.push({
                                    ...subItem,
                                    label: subItem.name,
                                });
                            }
                        });
                }
            });
            return aBusinessGroup;
        },
    },
    methods: {
        isLegalCustomer(customerId) {
            return (
                !this.cacheFilterParams ||
                (this.cacheFilterParams &&
                    this.cacheFilterParams.customerIds &&
                    (this.cacheFilterParams.customerIds.includes(customerId) ||
                        this.cacheFilterParams.customerIds.length == 0)) ||
                (this.cacheFilterParams && !this.cacheFilterParams.customerIds)
            );
        },
        selectCustomerChange(_, cumtomer) {
            let _customerIds = [],
                _customerDeparmentIds = [],
                _jobSerieIds = [],
                _businessGroupIds = [];

            if (cumtomer.length > 0) {
                cumtomer.forEach((item) => {
                    _customerIds.push(item.customerId);
                });
                _customerDeparmentIds =
                    (this.cacheFilterParams &&
                        this.cacheFilterParams.customerDeparmentIds) ||
                    [];
                _jobSerieIds =
                    (this.cacheFilterParams &&
                        this.cacheFilterParams.jobSerieIds) ||
                    [];
                _businessGroupIds =
                    (this.cacheFilterParams &&
                        this.cacheFilterParams._businessGroupIds) ||
                    [];
            }

            const obj = {
                customerIds: _customerIds,
                customerDeparmentIds: _customerDeparmentIds,
                jobSerieIds: _jobSerieIds,
                businessGroupIds: _businessGroupIds,
            };
            this.$emit("fresh", obj);
        },
        selectDepartmentChange(_, department) {
            let _customerIds = [],
                _customerDeparmentIds = [],
                _jobSerieIds = [],
                _businessGroupIds = [];

            if (department.length > 0) {
                _customerDeparmentIds = department.map((item) => item.id);
            }
            _customerIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.customerIds) ||
                [];
            _jobSerieIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.jobSerieIds) ||
                [];
            _businessGroupIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.businessGroupIds) ||
                [];

            const obj = {
                customerIds: _customerIds,
                customerDeparmentIds: _customerDeparmentIds,
                jobSerieIds: _jobSerieIds,
                businessGroupIds: _businessGroupIds,
            };
            this.$emit("fresh", obj);
        },
        selectJobChange(_, job) {
            console.log(job);
            let _customerIds = [],
                _customerDeparmentIds = [],
                _jobSerieIds = [],
                _businessGroupIds = [];

            if (job.length > 0) {
                _jobSerieIds = job.map((item) => item.id);
            }
            _customerIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.customerIds) ||
                [];
            _customerDeparmentIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.customerDeparmentIds) ||
                [];
            _businessGroupIds =
                (this.cacheFilterParams &&
                    this.cacheFilterParams.businessGroupIds) ||
                [];

            const obj = {
                customerIds: _customerIds,
                customerDeparmentIds: _customerDeparmentIds,
                jobSerieIds: _jobSerieIds,
                businessGroupIds: _businessGroupIds,
            };
            console.log(obj);
            this.$emit("fresh", obj);
        },
        businessGroupChange(_, businessGroup) {
            let _businessGroup = [];

            if (businessGroup.length > 0) {
                _businessGroup = businessGroup.map((item) => item.id);
            }

            const obj = {
                customerIds:
                    (this.cacheFilterParams &&
                        this.cacheFilterParams.customerIds) ||
                    [],
                customerDeparmentIds:
                    (this.cacheFilterParams &&
                        this.cacheFilterParams.customerDeparmentIds) ||
                    [],
                jobSerieIds:
                    (this.cacheFilterParams &&
                        this.cacheFilterParams.jobSerieIds) ||
                    [],
                businessGroupIds: _businessGroup,
            };
            this.$emit("fresh", obj);
        },
    },
};
</script>

<style lang="scss">
.business-line-data-table {
    .el-table--border .el-table__cell {
        border: none;
    }
    .el-table th.el-table__cell.is-leaf,
    .el-table td.el-table__cell {
        border: none;
    }
    .el-table thead th.el-table__cell {
        background: #f8f8f8;
    }
    .el-table__empty-block {
        width: 100% !important;
    }

    .ell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .num {
        color: #38bc9d;
        cursor: pointer;
    }
}
</style>